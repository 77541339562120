import * as React from "react"
import { Button, Container } from "react-bootstrap"
import FileIcon from '../components/icons/file'
import SearchIcon from '../components/icons/search'
import Layout from "../components/layout";
import BigLogo from "../components/bigLogo";
import ContactForm from "../components/contactForm";

const IndexPage = () => {
  return (
    <Layout>
      <BigLogo />
      <Container className="my-3 parallax d-flex flex-column flex-sm-row p-3 justify-content-around align-content-center flex-wrap">
        <Button variant="primary" size="lg" onClick={jumpToContactPage}>
          <FileIcon />Skontaktuj się z nami
        </Button>
        <Button id="corobimy" variant="primary" size="lg" onClick={scrollToButton}>
          <SearchIcon />Dowiedz się wiecej
        </Button>
      </Container>
      <Container className="container p-3 lh-lg">
        <h4>Co robimy</h4>
        <p className="mx-2">Przygotowujemy wszelkie formalności związane z instalacją farmy fotowoltaicznej na Twojej działce.</p>
        <p className="mx-2">Jesteśmy grupą ludzi, których łączy zamiłowanie do środowiska
        naturalnego i nowoczesnych technologii. Dlatego zdecydowanie stawiamy na
        zieloną energię. Wierzymy, że tania i zrównoważona przyszłość polskiej
        energetyki będzie opierać się na Fotowoltaice.</p>
        <p className="mx-2">Chcemy żeby nasza działalność niosła wymierne korzyści materialne dla
        naszych klientów. Jesteśmy profesjonalni, odpowiedzialni i bardzo
        zaangażowani w to, co robimy. Jeżeli posiadasz działkę spełniającą
        kryteria – skontaktuj się z nami koniecznie. Odpowiemy na wszystkie
        pytania.</p>
      </Container>
      <Container className="container p-3 lh-lg">
      <h4>Kryteria, jakie powinna spełniać działka</h4>
        <ul>
          <li>powierzchnia 1,5 ha lub większa</li>
          <li>nieosłonięta przez budynki i drzewa</li>
          <li>o IV lub niższej klasie gruntu</li>
          <li>z dojazdem utwardzaną drogą</li>
          <li>
            znajdująca się w odległości do 500 metrów od sieci średniego
            napięcia
          </li>
        </ul>
      </Container>
      <Container className="container p-3">
        <h4>Formularz kontaktowy</h4>
        <Container className="container px-2">
          <p>Zostaw swoje dane żebyśmy mogli się z Tobą skontakować:</p>
          <ContactForm />
        </Container>
      </Container>
    </Layout>
  )
}

const jumpToContactPage = () => {
  window.location.href = '/kontakt';
}

const scrollToButton = () => {
  document.getElementById('corobimy').scrollIntoView();;
}

export default IndexPage
